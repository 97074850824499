<template>
  <v-navigation-drawer v-model="Sidebar_drawer" :dark="SidebarColor !== 'white'" :color="SidebarColor"
    mobile-break-point="960" clipped :right="$vuetify.rtl" mini-variant-width="70" :expand-on-hover="expandOnHover" app
    id="main-sidebar">
    <v-list dense nav>
      <!---USer Area -->
      <!-- <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/men/81.jpg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Dohn Deo</v-list-item-title>
          <v-list-item-subtitle class="caption">Webdesigner</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item v-for="item in adminitems" :key="item.title" :to="item.to" :active-class="`green white--text`" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-home"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Web Site Contents</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in WebsiteContent"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-view-list"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">User</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in User"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-view-list"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Seller</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in Seller"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-cart"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Reports</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in Reports"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-information"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Static</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in stat"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userRole: localStorage.getItem("role"),
    adminitems: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard",
      },
      // {
      //   title: "User List",
      //   icon: "mdi-account -circle",
      //   to: "/userList",
      // },
      // {
      //   title: "Home",
      //   icon: "mdi-home",
      //   to: "/banner"
      // },
      {
        title: "Category",
        icon: "mdi-view-list",
        to: "/category",
      },
      // {
      //   title: "Sub-category",
      //   icon: "mdi-format-list-bulleted-square",
      //   to: "/subcategory",
      // },
      // {
      //   title: "Blog List",
      //   icon: "mdi-cards",
      //   to: "/blog",
      // },
      // {
      //   title: "About",
      //   icon: "mdi-information",
      //   to: "/about",
      // },
      
      // {
      //   title: "Purchase",
      //   icon: "mdi-cart",
      //   to: "/purchaseList",
      // },
      // {
      //   title: "Payment Report",
      //   icon: "mdi-cart",
      //   to: "/paymentReport",
      // },
    ],
    WebsiteContent: [
    { title: "Home", to: "/homeSlider" },
      { title: "About Us", to: "/about" },
      { title: "Our Story", to: "/story" },
      { title: "Goals", to: "/goal" },
      { title: "Middle Banner", to: "/middleBanner" },
      { title: "Blog List", to: "/blog" },
    ],
    User: [
    { title: "User List", to: "/userList" },
    ],
    Seller: [
    { title: "Seller Add", to: "/sellerAdd" },
    { title: "Seller List", to: "/sellerList" },
    ],
    Reports: [
   {
        title: "Order Report",
        icon: "mdi-cart",
        to: "/orderReport",
      },
      // {
      //   title: "Payment Report",
      //   icon: "mdi-cart",
      //   to: "/paymentReport",
      // },
    ],
    stat: [
      {
        title: "Privacy Policy",
        to: "/privacyPolicy",
      },
      {
        title: "Cancellation Policy",
        to: "/cancellationPolicy",
      },
      // {
      //   title: "Shipping Policy",
      //   to: "/static/Shipping Policy",
      // },
      // {
      //   title: "Refund Policy",
      //   to: "/static/Refund Policy",
      // },
      {
        title: "Terms and conditions",
        to: "/termsAndConditions",
      },
    ],
    Home: [
      { title: "Banner", to: "/banner" },
    ],
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {},
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {

    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }

  .success {
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
  }
}
</style>
